




import { Component, Vue } from 'vue-property-decorator'
import UserDetail from './components/UserDetail.vue'

@Component({
  name: 'UserProfile',
  components: {
    UserDetail
  }
})
export default class extends Vue {}
