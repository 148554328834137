























































import { Component, Prop, Vue } from 'vue-property-decorator'
import Sticky from '@/components/Sticky/index.vue'
import {
  errorMsg, getTranslations,
  successMsg,
  validateForm
} from '@/utils'
import { Form } from 'element-ui'
import {changePassword, getUser} from '@/api/users'
import { UserModule } from '@/store/modules/user'

@Component({
  name: 'ChangePassword',
  components: {
    Sticky
  }
})

export default class extends Vue {
  @Prop({ default: false }) private isEdit!: boolean
  @Prop({ default: false }) private isProfile!: boolean

  private saveLoader = false
  private ruleForm = {
    pass: '',
    checkPass: '',
    fullName: '',
    id: ''
  };

  mounted() {
    this.init()
  }

  private async init() {
    if (this.$route.params.id) {
      const { data } = await getUser({
        id: this.$route.params.id,
        withRoles: false
      })
      this.ruleForm.fullName = getTranslations(data)
      this.ruleForm.id = this.$route.params.id
    } else {
      this.ruleForm.fullName = getTranslations(UserModule)
      this.ruleForm.id = UserModule.id
    }
  }

  private validatePass = (rule: any, value: any, callback: any) => {
    if (value === '') {
      callback(new Error(this.$t('userValidation.pleaseInputPassword').toString()))
    } else {
      if (this.ruleForm.checkPass !== '') {
        (this.$refs.ruleForm as Form).validateField('checkPass')
      }
      callback()
    }
  };

  private validatePass2 = (rule: any, value: any, callback: any) => {
    if (value === '') {
      callback(new Error(this.$t('userValidation.pleaseInputPasswordAgain').toString()))
    } else if (value !== this.ruleForm.pass) {
      callback(new Error(this.$t('userValidation.twoInputsDontMatch').toString()))
    } else {
      callback()
    }
  };

  private rules = {
    pass: [
      { min: 5, message: this.$t('userValidation.atLeastCharacters'), trigger: 'blur' },
      { validator: this.validatePass, trigger: 'blur' }
    ],
    checkPass: [
      { validator: this.validatePass2, trigger: 'blur' }
    ]
  }

  private async saveItem() {
    try {
      const [valid] = await validateForm(this.$refs.ruleForm as Form)
      if (!valid) return errorMsg('form.formErrors')
      this.saveLoader = true
      await changePassword({
        user_id: this.ruleForm.id,
        password: this.ruleForm.pass
      })
      await successMsg('userDetail.successSave')
    } catch (err) {
      await errorMsg('api.serverError')
    } finally {
      this.saveLoader = false
    }
  }
}
